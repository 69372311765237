//* Interfaces
import { SideMenuInterface } from 'src/interface/components/modules/sideMenu'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import SmartToyIcon from '@mui/icons-material/SmartToy'

//* constants
import { urls, pages } from 'src/constants/index'

export const SideMenuData: SideMenuInterface[] = [
  {
    title: pages.page.QUALITY_CHECK_PAGE,
    to: urls.pagesUrls.QUALITY_CHECK_PAGE,
    icon: <SmartToyIcon />,
  },
  {
    title: pages.page.RECORD_PAGE,
    to: urls.pagesUrls.RECORD_PAGE,
    icon: <AutoAwesomeMotionIcon />,
  },
]
