import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'

//* Components
import { record, qualityCheck } from 'src/pages'
import { PublicRoute, NotFound } from 'src/adapters'

//* Constants
import { urls } from 'src/constants/index'

const AppRoute: FC = () => {
  return (
    <Routes>
      <Route path="/">
        <Route element={<PublicRoute />}>
          <Route
            path={urls.pagesUrls.QUALITY_CHECK_PAGE}
            element={<qualityCheck.QualityCheckPage />}
          />
          <Route
            path={urls.pagesUrls.RECORD_PAGE}
            element={<record.RecordPage />}
          />
        </Route>
        <Route
          path={urls.pagesUrls.NOT_FOUND}
          element={<NotFound path={urls.pagesUrls.QUALITY_CHECK_PAGE} />}
        />
      </Route>
    </Routes>
  )
}

export default AppRoute
