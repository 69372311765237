const headers = [
  { label: 'Model Type', key: 'modelType' },
  { label: 'Clean loin severity', key: 'cleanLoinSev' },
  { label: 'Orange meat severity', key: 'orageMeatSev' },
  { label: 'Bruise severity', key: 'bruiseSev' },
  { label: 'Metal Stain severity', key: 'metalStainSev' },
  { label: 'Curd severity', key: 'curdServ' },
  { label: 'Pasty severity', key: 'pastySev' },
  { label: 'Create Time', key: 'createdAt' },
  { label: 'Original Image', key: 'imagePathOrigin' },
  { label: 'Result Image', key: 'imagePathResult' },
]

export { headers }
