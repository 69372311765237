import { FC } from 'react'
import { ButtonWithTooltipPropsInterface } from 'src/components/button/Button'

/**
 * Button loading component that show instead of regular button when loading state.
 * @returns A button with loading spin animation.
 */

const ButtonWithSpinLoading: FC<ButtonWithTooltipPropsInterface> = (props) => {
  const {
    bgcolor,
    children,
    width,
    height,
    rounded,
    wrapClass,
    onlyIcon,
    ...rest
  } = props

  return (
    <div
      className={` ${width} ${height} ${rounded} ${bgcolor} inline-flex justify-center items-center 
                    px-4 py-2 font-semibold leading-6 text-sm shadow text-white transition 
                    ease-in-out duration-150 cursor-not-allowed ${wrapClass}`}
      {...rest}
    >
      <svg
        className={`animate-spin -ml-1 ${
          onlyIcon ? '' : 'mr-3'
        } h-5 w-5 text-white`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          className="opacity-25"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {children}
    </div>
  )
}

export { ButtonWithSpinLoading }
