const Footer = () => {
  return (
    <>
      <footer
        className="shadow md:ml-footerLeft py-6 px-16 border-t border-gray-200 font-light flex flex-col
              lg:flex-row justify-center items-center xl:h-20 relative bottom-0 left-0 bg-bgSecondary"
      >
        <p className="text-gray-700 text-center lg:text-left mb-0">
          Copyright &copy; {new Date().getFullYear()}{' '}
          <a
            href="https://www.thaiunion.com/th"
            target="_blank"
            rel="noreferrer"
            className="text-light-blue-500 hover:text-light-blue-700"
          >
            Thai Union Group PLC All rights reserved.
          </a>
        </p>
      </footer>
    </>
  )
}

export default Footer
