import { MODEL_TYPE_CLASS4, MODEL_TYPE_CLASS6 } from 'src/constants/api/api'

const tunaModelMap = new Map<
  string,
  { name: string; color: string; db_name: string }
>([
  [
    'CLEANED_LOIN',
    {
      name: 'Clean loin',
      color: 'bg-cleanLoin',
      db_name: 'cleanLoinSev',
    },
  ],
  [
    'ORANGE_MEAT',
    {
      name: 'Orange meat',
      color: 'bg-orangeMeat',
      db_name: 'orageMeatSev',
    },
  ],
  [
    'PASTY',
    {
      name: 'Pasty',
      color: 'bg-pasty',
      db_name: 'pastySev',
    },
  ],
  [
    'BRUISE',
    {
      name: 'Bruise',
      color: 'bg-bruise',
      db_name: 'bruiseSev',
    },
  ],
  [
    'METAL_STAIN',
    {
      name: 'Metal Stain',
      color: 'bg-metalStain',
      db_name: 'metalStainSev',
    },
  ],
  [
    'CURD',
    {
      name: 'Curd',
      color: 'bg-curd',
      db_name: 'curdServ',
    },
  ],
])

const tunaModelServerityMap = new Map([
  [
    MODEL_TYPE_CLASS4.toString(),
    [
      { key: 'cleanLoinSev', key_map: 'CLEANED_LOIN' },
      { key: 'orageMeatSev', key_map: 'ORANGE_MEAT' },
      { key: 'bruiseSev', key_map: 'BRUISE' },
      { key: 'metalStainSev', key_map: 'METAL_STAIN' },
    ],
  ],
  [
    MODEL_TYPE_CLASS6.toString(),
    [
      { key: 'cleanLoinSev', key_map: 'CLEANED_LOIN' },
      { key: 'orageMeatSev', key_map: 'ORANGE_MEAT' },
      { key: 'bruiseSev', key_map: 'BRUISE' },
      { key: 'metalStainSev', key_map: 'METAL_STAIN' },
      { key: 'pastySev', key_map: 'PASTY' },
      { key: 'curdServ', key_map: 'CURD' },
    ],
  ],
])

const modelMap = new Map<string, string>([
  ['ALL', 'Both'],
  ['FOUR_CLASS', 'Four Classes'],
  ['SIX_CLASS', 'Six Classes'],
])

export { modelMap, tunaModelMap, tunaModelServerityMap }
