import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import 'dayjs/locale/th' // import locale
import 'dayjs/locale/en' // import locale

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat) // use plugin

const convertISO2LocalTime = (
  isoTimeStr: string,
  dateFormat = 'DD MMM YYYY HH:mm:ss',
) => {
  return dayjs(new Date(isoTimeStr)).local().format(dateFormat)
}

export { convertISO2LocalTime }
