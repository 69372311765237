import { FC } from 'react'
import { Navigate } from 'react-router-dom'

interface INotFoundProps {
  path: string
}

const NotFound: FC<INotFoundProps> = ({ path }) => {
  return <Navigate to={path} />
}

export default NotFound
