import React, { Dispatch, MutableRefObject, SetStateAction } from 'react'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

import { useScreen } from 'src/hooks'

import { modal } from 'src/components'

import { screens } from 'src/constants'
import { IModalData } from 'src/interface/components/modal'

interface IQualityCheckModalPageProps {
  webcamRef: MutableRefObject<any>
  modalData: IModalData
  setModalData: Dispatch<SetStateAction<IModalData>>
  handdleOnClickCapture: (e?: any) => void
}

const QualityCheckModalPage: React.FC<IQualityCheckModalPageProps> = (
  props,
) => {
  const { modalData, setModalData, handdleOnClickCapture } = props

  const isScreen = useScreen()

  return (
    <>
      <modal.Modal
        modalData={modalData}
        setModalData={setModalData}
        width={isScreen === screens.screen.MOBILE ? 'w-full' : 'w-5/12'}
      >
        <div className="flex flex-col gap-5">
          <Camera
            onTakePhoto={(dataUri) => {
              handdleOnClickCapture(dataUri)
            }}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
        </div>
      </modal.Modal>
    </>
  )
}

export default QualityCheckModalPage
