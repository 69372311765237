import { screens } from 'src/constants'

import { useMediaQuery } from 'react-responsive'

function useScreen() {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return isMobile
    ? screens.screen.MOBILE
    : isTablet
    ? screens.screen.TABLET
    : screens.screen.DESKTOP
}

export default useScreen
