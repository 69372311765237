const CardLoading = () => (
  <div className="animate-pulse flex flex-col justify-center items-center gap-5">
    <div className="w-11/12 h-64 flex flex-row p-5 justify-around items-center gap-5 bg-gray-200">
      <div className="h-full w-full bg-gray-300"></div>
      <div className="h-full w-full bg-gray-300"></div>
    </div>
    <div className="w-11/12 h-64 flex flex-row p-5 justify-around items-center gap-5 bg-gray-200">
      <div className="h-full w-full bg-gray-300"></div>
      <div className="h-full w-full bg-gray-300"></div>
    </div>
  </div>
)

export default CardLoading
