import { atom } from 'recoil'

const loadingAtom = atom({
  key: 'loading',
  default: {
    loading: false,
  },
})

const sideMenuPosistionAtom = atom({
  key: 'sideMenuPosistion',
  default: {
    sideMenuPosistion: '-left-64',
  },
})

export { loadingAtom, sideMenuPosistionAtom }
