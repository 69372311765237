import React, { MutableRefObject } from 'react'

import { dropDown, button, card, loading } from 'src/components'

import { api } from 'src/constants'
import cameraButton from 'src/assets/camera-button.svg'
import folderButton from 'src/assets/folder-button.svg'

interface IQualityCheckSelectPageProps {
  inputRef: MutableRefObject<any>
  classType: string
  handdleOnClickCamera: (e?: any) => void
  handdleOnClickFolder: (e?: any) => void
  handleSelectFile: (e?: any) => void
  onSelectDropDown: (e?: any) => void
  isLoading: boolean
}

const QualityCheckSelectPage: React.FC<IQualityCheckSelectPageProps> = (
  props,
) => {
  const {
    inputRef,
    classType,
    handdleOnClickCamera,
    handdleOnClickFolder,
    handleSelectFile,
    onSelectDropDown,
    isLoading,
  } = props

  return (
    <>
      {isLoading ? (
        <card.CardHover title="Quality check">
          <div className="flex flex-row justify-around gap-3">
            <loading.ImageLoading />
            <loading.ImageLoading />
          </div>
          <div className="flex flex-col justify-center gap-3 ml-3">
            <loading.TextLoading />
            <loading.TextLoading />
            <loading.TextLoading />
            <loading.TextLoading />
            <loading.TextLoading />
          </div>
        </card.CardHover>
      ) : (
        <>
          <card.CardHover title="Quality check">
            <button.Button
              width="w-3/4"
              height="h-10"
              onClick={handdleOnClickCamera}
            >
              <img src={cameraButton} alt="camera button" />
            </button.Button>
            <button.Button
              width="w-3/4"
              height="h-10"
              onClick={handdleOnClickFolder}
            >
              <img src={folderButton} alt="fodler button" />
            </button.Button>
            <input
              className="hidden"
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleSelectFile}
            />
            <div className=" flex flex-col justify-center gap-3">
              <h2 className="pl-10 font-bold">Select Class : </h2>
              <dropDown.DropDown
                datas={api.DATA_DROP_DOWN()}
                value={classType}
                handleOnSelect={onSelectDropDown}
                width={'w-3/4'}
              />
            </div>
          </card.CardHover>
        </>
      )}
    </>
  )
}

export default QualityCheckSelectPage
