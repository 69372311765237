import React from 'react'

import { loading } from 'src/components'

interface IModalLoadingProps {}

const ModalLoading: React.FC<IModalLoadingProps> = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-5 justify-center">
        <div className="flex flex-row justify-around gap-3">
          <loading.ImageLoading />
          <loading.ImageLoading />
        </div>
        <div className="flex flex-col justify-center gap-3 ml-3">
          <loading.TextLoading />
          <loading.TextLoading />
          <loading.TextLoading />
          <loading.TextLoading />
          <loading.TextLoading />
        </div>
      </div>
    </>
  )
}

export default ModalLoading
