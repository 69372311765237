import { FC } from 'react'

interface ICameraDropDownProps {
  datas: any
  handleOnSelect: Function
  width?: string
  height?: string
  wrapClass?: string
  value?: string
}

const DropDown: FC<ICameraDropDownProps> = (props) => {
  const { datas, handleOnSelect, width, height, value } = props
  return (
    <div className="flex justify-center">
      <select
        className={`rounded-md px-3 text-base font-normal 
                    text-black focus:outline-none border-2
                    bg-sideBarSelect ${width} ${height}
                    transition ease-in-out shadow-sm `}
        onChange={(e) => handleOnSelect(e.target.value)}
        value={value}
      >
        {datas.map((data: any, index: number) => {
          return (
            <option key={index} value={data.id} className="text-left">
              {data.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

DropDown.defaultProps = {
  width: 'w-11/12',
  height: 'h-12',
}

export default DropDown
