import React, { Dispatch, SetStateAction } from 'react'

import { modal, loading } from 'src/components'

import { useScreen } from 'src/hooks'

import { screens, map } from 'src/constants'
import { helpers } from 'src/utils'
import { IModalData } from 'src/interface/components/modal'

interface IRecordModalPageProps {
  modalData: IModalData
  setModalData: Dispatch<SetStateAction<IModalData>>
  tuanResult: any
}

const RecordModalPage: React.FC<IRecordModalPageProps> = (props) => {
  const { modalData, setModalData, tuanResult } = props

  const isScreen = useScreen()

  return (
    <>
      <modal.Modal
        modalData={modalData}
        setModalData={setModalData}
        width={isScreen === screens.screen.MOBILE ? 'w-full' : 'w-5/12'}
      >
        <>
          {tuanResult ? (
            <>
              <div className="flex flex-row justify-around gap-3">
                <div className="h-full w-full flex items-center justify-center border-2 border-black bg-bgSecondary">
                  <img
                    src={helpers.generals.file.convertPath2URL(
                      tuanResult[0].imagePathOrigin,
                    )}
                    onError={(e: any) => {
                      ;(e.target as HTMLElement).onerror = null
                    }}
                    alt="Original image preview"
                    className="w-44 object-contain"
                  />
                </div>
                <div className="h-full w-full flex items-center justify-center border-2 border-black bg-bgSecondary">
                  <img
                    src={helpers.generals.file.convertPath2URL(
                      tuanResult[0].imagePathResult,
                    )}
                    onError={(e: any) => {
                      ;(e.target as HTMLElement).onerror = null
                    }}
                    alt="Result image preview"
                    className="w-44 object-contain"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center gap-3">
                {map.tunaModelServerityMap
                  .get(tuanResult[0].modelType)
                  .map((severity_key, index) => {
                    return (
                      <div key={index} className="flex flex-row items-center">
                        <div
                          className={`w-5 h-5 ${
                            map.tunaModelMap.get(severity_key.key_map).color
                          } border-2 border-black mr-5`}
                        ></div>
                        <div className="flex flex-row justify-between w-9/12">
                          <p className="font-bold">
                            {map.tunaModelMap.get(severity_key.key_map).name}{' '}
                            severity (%) :
                          </p>
                          <p>
                            {(tuanResult[0][severity_key.key] * 100).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </>
          ) : (
            <loading.ModalLoading />
          )}
        </>
      </modal.Modal>
    </>
  )
}

export default RecordModalPage
