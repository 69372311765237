/**
 * Set the value of the local storage item "camera" to the given camera ID
 * @param {string} classe - The classes ID.
 */
const setClasses = (classe: string) => localStorage.setItem('classes', classe)

/**
 * Get the camera from local storage if it exists, otherwise return an empty string
 */
const getClasses = (): string => localStorage.getItem('classes') || ''

/**
 * Clear the local storage
 */
const clearLocal = () => localStorage.clear()

export { setClasses, getClasses, clearLocal }
