const getRecord = (modelType: string, skip: number, take: number = 2): any => {
  const getRecord = `query getRecord ($where: RecordWhereInput, $orderBy: [RecordOrderByWithRelationInput!], $take: Int, $skip: Int) {
                          getRecord (where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                              rid
                              imagePathResult
                              imagePathOrigin
                          }
                      }`
  if (modelType === 'ALL')
    return {
      query: getRecord,
      variables: {
        orderBy: {
          createdAt: 'desc',
        },
        take: take,
        skip: take * (skip - 1),
      },
    }
  return {
    query: getRecord,
    variables: {
      where: {
        modelType: {
          equals: modelType,
        },
      },
      orderBy: {
        createdAt: 'desc',
      },
      take: take,
      skip: take * (skip - 1),
    },
  }
}

const getRecords = (modelType: string): any => {
  const getRecord = `query getRecord ($where: RecordWhereInput, $orderBy: [RecordOrderByWithRelationInput!]) {
                          getRecord (where: $where, orderBy: $orderBy) {
                              imagePathResult
                              imagePathOrigin
                              cleanLoinSev
                              orageMeatSev
                              pastySev
                              bruiseSev
                              metalStainSev
                              curdServ
                              modelType
                              imagePathResult
                              imagePathOrigin
                              createdAt
                          }
                      }`
  if (modelType === 'ALL')
    return {
      query: getRecord,
      variables: {
        orderBy: {
          createdAt: 'desc',
        },
      },
      pause: false,
    }
  return {
    query: getRecord,
    variables: {
      where: {
        modelType: {
          equals: modelType,
        },
      },
      orderBy: {
        createdAt: 'desc',
      },
    },
    pause: false,
  }
}

const getRecordByrId = (rId: string): any => {
  const getRecordByrId = `query getRecord ($where: RecordWhereInput) {
                          getRecord (where: $where) {
                              rid
                              imagePathResult
                              imagePathOrigin
                              cleanLoinSev
                              orageMeatSev
                              pastySev
                              bruiseSev
                              metalStainSev
                              curdServ
                              modelType
                              imagePathResult
                              imagePathOrigin
                          }
                      }`

  return {
    query: getRecordByrId,
    variables: {
      where: {
        rid: {
          equals: rId,
        },
      },
    },
  }
}

export { getRecord, getRecords, getRecordByrId }
