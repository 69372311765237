import { PropsWithChildren } from 'react'
import { useRecoilState } from 'recoil'

import { recoil } from 'src/store'
import { useScreen } from 'src/hooks'

import { screens } from 'src/constants'

//* Interfaces or Types
interface IComponentWithChildProps {
  children: PropsWithChildren<{ child?: string }>
}

const MainContent = ({ children }: IComponentWithChildProps) => {
  const [sidemenuPos, setSidemenuPos] = useRecoilState(
    recoil.applicationStore.selector.sideMenuPosistionSelector,
  )
  const isScreen = useScreen()

  const onClickLayout = () => {
    setSidemenuPos('-left-64')
  }
  return (
    <>
      {sidemenuPos !== '-left-64' && isScreen !== screens.screen.DESKTOP ? (
        <div
          className="
            fixed w-full h-screen z-10
            overflow-hidden bg-gray-700 opacity-75"
          style={{ minHeight: 'calc(100vh - (90px + 5rem))' }}
          onClick={onClickLayout}
        />
      ) : (
        ''
      )}
      <div
        className={`${
          isScreen !== screens.screen.DESKTOP
            ? 'md:px-52 xl:px-52'
            : 'md:pl-52 xl:pl-52'
        } min-h-screen py-5 px-7 md:py-5`}
        style={{ minHeight: 'calc(100vh - (90px + 5rem))' }}
      >
        {children}
      </div>
    </>
  )
}

export default MainContent
