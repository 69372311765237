import { ITunaAssetment } from 'src/interface/services/api/tuna'

const tunaModelAssetmentHandler = async (
  mutationTunaAssetment: any,
  data: ITunaAssetment,
) => {
  const result = {
    image_result: '',
    severities: [],
  }

  await mutationTunaAssetment.mutateAsync(data).then((res) => {
    result.image_result = res.data.image_result
    result.severities = res.data.severities
  })

  return result
}

export { tunaModelAssetmentHandler }
