import React, { ReactNode } from 'react'

interface ICardHoverProps {
  title: string
  children: ReactNode
  width?: string
}

const CardHover: React.FC<ICardHoverProps> = (props) => {
  const { title, width, children } = props
  return (
    <>
      <div
        className={`flex flex-col ${width} shadow-xl bg-secondary rounded-lg`}
      >
        <div className="bg-primary rounded-t-lg p-3">
          <h1 className="text-xl text-textPrimary font-bold">{title}</h1>
        </div>
        <div
          className="flex flex-col justify-center rounded-b-lg p-5 gap-10 my-5
                    bg-secondary"
        >
          {children}
        </div>
      </div>
    </>
  )
}

CardHover.defaultProps = {
  width: 'w-96',
}

export { CardHover }
