import { useRecoilState } from 'recoil'

//* Component
import { SideMenuData } from 'src/components/modules/sideMenu/constants/SideMenuData'
import SideMenuLink from 'src/components/modules/sideMenu/SideMenuLink'

import { recoil } from 'src/store'

const SideMenu = () => {
  const [sideMenuPos] = useRecoilState(
    recoil.applicationStore.selector.sideMenuPosistionSelector,
  )
  return (
    <>
      <div
        className={`fixed w-sidemenu bg-secondary shadow-xl
                    overflow-y-auto z-30 transition-all 
                    duration-300 ${sideMenuPos} lg:-left-0`}
        style={{ minHeight: 'calc(100vh - 90px)' }}
      >
        <div className="flex flex-col flex-1 justify-between h-auto text-white">
          <ul
            className="flex-col min-w-full flex list-none mt-14 gap-10 "
            style={{ minHeight: 'calc(100vh - (60px + 7rem))' }}
          >
            {SideMenuData.map((sideMenu, index) => (
              <SideMenuLink
                key={index}
                title={sideMenu.title}
                icon={sideMenu.icon}
                to={sideMenu.to}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SideMenu
