import React, { useState } from 'react'

import { button, card, image, modal, loading } from 'src/components'

import { map } from 'src/constants'
import { ITunaQualityCheckResult } from 'src/interface/pages/qualityCheck'

interface IQualityCheckResultPageProps {
  tuanResult: ITunaQualityCheckResult
  handdleOnCancel: (e?: any) => void
  handdleOnSave: (e?: any) => void
  isLoading: boolean
}

const QualityCheckResultPage: React.FC<IQualityCheckResultPageProps> = (
  props,
) => {
  const { tuanResult, handdleOnCancel, handdleOnSave, isLoading } = props
  const [open, setOpen] = useState(false)

  const handdleOnClickCancelResult = () => {
    setOpen(true)
  }

  const onClickConfirmCancel = () => {
    handdleOnCancel()
  }

  return (
    <>
      {isLoading ? (
        <card.CardHover title="Result">
          <loading.ModalLoading />
        </card.CardHover>
      ) : (
        <>
          <card.CardHover title="Result">
            <div className="flex flex-row justify-around gap-3">
              <image.PreviewImage image_base64={tuanResult.image_origin} />
              <image.PreviewImage image_base64={tuanResult.image_result} />
            </div>
            <div className="flex flex-col justify-center gap-3">
              {map.tunaModelServerityMap
                .get(tuanResult.model_type)
                .map((data_ser, index) => {
                  return (
                    <div key={index} className="flex flex-row items-center">
                      <div
                        className={`w-5 h-5 ${
                          map.tunaModelMap.get(data_ser.key_map).color
                        } border-2 border-black mr-5`}
                      ></div>
                      <div className="flex flex-row justify-between w-9/12">
                        <p className="font-bold">
                          {map.tunaModelMap.get(data_ser.key_map).name} severity
                          (%) :
                        </p>
                        <p>
                          {(
                            (tuanResult.severities.filter(
                              (data) => data.name === data_ser.key_map,
                            )[0].value || 0) * 100
                          ).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="flex flex-row w-full justify-around">
              <button.Button
                width="w-32"
                height="h-10"
                bgcolor="bg-red-600"
                onClick={handdleOnClickCancelResult}
              >
                <h1 className="text-black">Cancel</h1>
              </button.Button>
              <button.Button
                width="w-32"
                height="h-10"
                bgcolor="bg-green-500"
                onClick={handdleOnSave}
              >
                <h1 className="text-black">Save</h1>
              </button.Button>
            </div>
          </card.CardHover>
          <modal.Dialog
            open={open}
            setOpen={setOpen}
            onClickConfirm={onClickConfirmCancel}
          />
        </>
      )}
    </>
  )
}

export default QualityCheckResultPage
