import React, { FC, ReactElement, useRef, useEffect } from 'react'

//* External Component, ui, form, etc...
import { button } from 'src/components'

import xButtonSideBar from 'src/assets/x-button-side-bar.svg'
import { IModalData } from 'src/interface/components/modal'

//* Props Interface
interface IModalProps {
  modalData: IModalData
  setModalData: any
  width?: string
  children: ReactElement
}

const Modal: FC<IModalProps> = (props) => {
  const { modalData, setModalData, width, children } = props
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  const onClickCancel = () => {
    setModalData({
      ...props.modalData,
      isShowing: !props.modalData.isShowing,
    })
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickCancel()
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const RenderModal = () => {
    if (!modalData.isShowing) {
      return
    }

    return (
      <>
        <div
          className="inset-0 z-30 bg-black fixed w-full h-screen
                      overflow-hidden opacity-25"
        />
        <div
          ref={wrapperRef}
          className="justify-center items-center flex overflow-x-hidden 
                    overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
        >
          <div
            className={`flex flex-col ${width} shadow-xl bg-secondary rounded-lg`}
          >
            <div className="bg-primary rounded-t-lg py-3 px-5 flex flex-row justify-between items-center">
              <h1 className="text-xl text-textPrimary font-bold">
                {modalData.title}
              </h1>
              <button.Button
                width="w-3/4"
                height="h-10"
                bgcolor="bg-transparent"
                onClick={onClickCancel}
              >
                <img src={xButtonSideBar} alt="x button side bar" />
              </button.Button>
            </div>
            <div
              className="flex flex-col justify-center rounded-b-lg p-5 gap-10 my-5
                    bg-secondary"
            >
              {children}
            </div>
          </div>
        </div>
      </>
    )
  }

  return <>{RenderModal()}</>
}

Modal.defaultProps = {
  width: 'w-96',
}

export default Modal
