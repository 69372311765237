import React, { useState } from 'react'
import { useQuery, useMutation } from 'urql'
import { CSVLink } from 'react-csv'

import {
  dropDown,
  card,
  button,
  snackBar,
  modal,
  loading,
} from 'src/components'
import { helpers } from 'src/utils'
import { graphqlService } from 'src/services'

import RecordModalPage from 'src/pages/record/RecordModalPage'

import { api, map, csv } from 'src/constants'
import { IModalData } from 'src/interface/components/modal'
import deleteButton from 'src/assets/delete-button.svg'

//* Props Interface
interface IModelPageProps {}

const RecordPage: React.FC<IModelPageProps> = () => {
  const [classType, setClassType] = useState(api.MODEL_TYPE_CLASS4)
  const [recordId, setRecordId] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [disablePrevious, setDisablePrevious] = useState(true)
  const [disableNext, setDisableNext] = useState(false)
  const [open, setOpen] = useState(false)
  const [csvData, setCsvData] = useState([])

  const [openSnackBarState, setOpenSnackBarState] = useState(false)

  const [modalData, setModalData] = useState<IModalData>({
    title: '',
    isShowing: false,
  })

  const [recordDatas, executeQuery] = useQuery(
    graphqlService.record.query.getRecord(classType, pageNumber),
  )

  const [recordDatasCSV] = useQuery(
    graphqlService.record.query.getRecords(classType),
  )

  const [recordData] = useQuery(
    graphqlService.record.query.getRecordByrId(recordId),
  )

  const [deleteRecordResult, deleteRecord] = useMutation(
    graphqlService.record.mutation.deleteRecord,
  )

  const totalPage =
    !recordDatasCSV.fetching && recordDatasCSV.data.getRecord
      ? Math.round(recordDatasCSV.data.getRecord.length / 2)
      : 0

  const onSelectDropDown = (data) => {
    setClassType(data)
    setPageNumber(1)
    setDisablePrevious(true)
    setDisableNext(false)
  }

  const handdleOnDownloadFile = async () => {
    const data = recordDatasCSV.data.getRecord.map((data) => {
      return {
        ...data,
        createdAt: helpers.generals.time.convertISO2LocalTime(data.createdAt),
        imagePathOrigin: helpers.generals.file.convertPath2URL(
          data.imagePathOrigin,
        ),
        imagePathResult: helpers.generals.file.convertPath2URL(
          data.imagePathResult,
        ),
        modelType: map.modelMap.get(data.modelType),
      }
    })
    setCsvData(data)
  }

  const onClickConfirm = async () => {
    await graphqlService.record.handler.handleDeleteRecord(
      { rid: recordId },
      deleteRecord,
    )

    if (classType === 'ALL') {
      executeQuery({
        variables: {
          orderBy: {
            createdAt: 'desc',
          },
          take: 2,
          skip: 2 * (pageNumber - 1),
        },
      })
      return
    }

    executeQuery({
      variables: {
        where: {
          modelType: {
            equals: classType,
          },
        },
        orderBy: {
          createdAt: 'desc',
        },
        take: 2,
        skip: 2 * (pageNumber - 1),
      },
    })

    setOpen(false)
  }

  const handdleOnDeleteRecord = (rId: string) => {
    setRecordId(rId)
    setOpen(true)
  }

  const handdleOnClickImage = (rId: string) => {
    setRecordId(rId)
    setModalData({ title: 'Record', isShowing: true })
  }

  const handdleOnPageChangeNext = () => {
    if (totalPage <= 1) return

    setDisablePrevious(false)
    setPageNumber(pageNumber + 1)
    if (pageNumber + 1 === Math.round(recordDatasCSV.data.getRecord.length / 2))
      setDisableNext(true)
  }

  const handdleOnPageChangePrevious = () => {
    if (totalPage <= 1) return

    if (pageNumber === 1) {
      setDisablePrevious(true)
      return
    }
    if (pageNumber - 1 === 1) setDisablePrevious(true)
    setDisableNext(false)
    setPageNumber(pageNumber - 1)
  }

  const renderCaseLoadingOrErorr = () => {
    if (recordDatas.fetching) return <loading.CardLoading />
    if (recordDatas.error) {
      if (disableNext) setDisableNext(true)
      if (pageNumber !== 1) setPageNumber(pageNumber - 1)
    }
  }

  return (
    <>
      <div className="flex justify-center my-5">
        <card.CardHover width={'w-96 lg:w-7/12'} title="Result Record">
          <div className="flex flex-col lg:flex-row lg:justify-items-start gap-5 items-center">
            <div className="xl:ml-10">
              <h2 className="font-bold">Select Class : </h2>
              <dropDown.DropDown
                datas={api.DATA_DROP_DOWN(true)}
                value={classType}
                handleOnSelect={onSelectDropDown}
                width={'w-64 lg:w-48'}
              />
            </div>
            <div className="mt-6">
              <button.Button
                width={'w-64 lg:w-52'}
                height="h-11"
                onClick={handdleOnDownloadFile}
                wrapClass="px-3"
              >
                <CSVLink
                  filename={`Record ${map.modelMap.get(
                    classType,
                  )} ${new Date().toDateString()}`}
                  data={csvData}
                  headers={csv.headers}
                >
                  <h1 className="px text-base font-normal text-white">
                    Download the data
                  </h1>
                </CSVLink>
              </button.Button>
            </div>
          </div>
          {!recordDatas.fetching &&
          !recordDatas.error &&
          !deleteRecordResult.fetching ? (
            <>
              <div className="flex flex-col justify-center items-center gap-10">
                {recordDatas.data.getRecord.map((data) => (
                  <div
                    key={data.rid}
                    className="w-11/12 h-64 bg-sideBarSelect shadow-2xl flex flex-col rounded-xl p-3"
                  >
                    <button
                      onClick={() => handdleOnClickImage(data.rid)}
                      className="flex-1 flex flex-row p-1 justify-around items-center gap-1"
                    >
                      <div className="h-full w-full flex items-center justify-center border-2 border-black bg-bgSecondary">
                        <img
                          src={helpers.generals.file.convertPath2URL(
                            data.imagePathOrigin,
                          )}
                          onError={(e: any) => {
                            ;(e.target as HTMLElement).onerror = null
                          }}
                          alt="Original image preview"
                          className="w-44 h-44 object-contain"
                        />
                      </div>
                      <div className="h-full w-full flex items-center justify-center border-2 border-black bg-bgSecondary">
                        <img
                          src={helpers.generals.file.convertPath2URL(
                            data.imagePathResult,
                          )}
                          onError={(e: any) => {
                            ;(e.target as HTMLElement).onerror = null
                          }}
                          alt="Result image preview"
                          className="w-44 h-44 object-contain"
                        />
                      </div>
                    </button>
                    <div className="p-1 flex justify-start">
                      <button.Button
                        width="w-10"
                        height="h-7"
                        onClick={() => handdleOnDeleteRecord(data.rid)}
                        bgcolor="bg-gray-300"
                        wrapClass="px-3 border-2 border-black"
                      >
                        <img src={deleteButton} alt="delete button" />
                      </button.Button>
                    </div>
                  </div>
                ))}
              </div>
              <RecordModalPage
                modalData={modalData}
                setModalData={setModalData}
                tuanResult={
                  recordData.fetching ? null : recordData.data.getRecord
                }
              />
            </>
          ) : (
            <div>{renderCaseLoadingOrErorr()}</div>
          )}
          <div className="flex fle-row justify-center items-center gap-5">
            <button.Button
              width="w-20"
              height="h-8"
              bgcolor="bg-gray-200"
              onClick={handdleOnPageChangePrevious}
              wrapClass="px-3 border-2 border-black"
              disabled={disablePrevious}
            >
              <h1 className="text-black">Previous</h1>
            </button.Button>
            <div>
              <h1 className="font-bold">
                Page {pageNumber} / {totalPage}
              </h1>
            </div>
            <button.Button
              width="w-20"
              height="h-8"
              bgcolor="bg-gray-200"
              onClick={handdleOnPageChangeNext}
              wrapClass="px-3 border-2 border-black"
              disabled={disableNext}
            >
              <h1 className="text-black">Next</h1>
            </button.Button>
          </div>
        </card.CardHover>
      </div>
      <snackBar.SnackBarCom
        message="Success"
        open={openSnackBarState}
        setOpen={setOpenSnackBarState}
      />
      <modal.Dialog
        open={open}
        setOpen={setOpen}
        onClickConfirm={onClickConfirm}
      />
    </>
  )
}

export default RecordPage
