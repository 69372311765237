const createRecord = ` mutation createRecord ($data: RecordCreateInput!) {
                        createRecord (data: $data) {
                            rid
                        }
                      }`

const deleteRecord = ` mutation deleteRecord ($where: RecordWhereUniqueInput!) {
                          deleteRecord (where: $where) {
                              rid
                          }
                        }`

export { createRecord, deleteRecord }
