const API_DATA_URL = `https://${process.env.REACT_APP_API_DATA_URL}/graphql`
const API_AI_URL = `https://${process.env.REACT_APP_API_AI_URL}`
const STORAGE_URL = `https://${process.env.REACT_APP_STORAGE_URL}`

const MODEL_TYPE_CLASS4 = 'FOUR_CLASS'
const MODEL_TYPE_CLASS6 = 'SIX_CLASS'

const DATA_DROP_DOWN = (selectAll?: boolean) => {
  if (selectAll)
    return [
      {
        id: 'ALL',
        name: 'Select All',
      },
      {
        id: MODEL_TYPE_CLASS4,
        name: '4 Classes',
      },
      {
        id: MODEL_TYPE_CLASS6,
        name: '6 Classes',
      },
    ]

  return [
    {
      id: MODEL_TYPE_CLASS4,
      name: '4 Classes',
    },
    {
      id: MODEL_TYPE_CLASS6,
      name: '6 Classes',
    },
  ]
}

export {
  API_AI_URL,
  API_DATA_URL,
  DATA_DROP_DOWN,
  MODEL_TYPE_CLASS4,
  MODEL_TYPE_CLASS6,
  STORAGE_URL,
}
