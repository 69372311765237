import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as Urql from 'urql'
import { QueryClient, QueryClientProvider } from 'react-query'

// * Components
import { modules } from 'src/components'
import { AppRoute } from 'src/adapters'

// * Service
import { graphqlService } from 'src/services'

function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <Urql.Provider value={graphqlService.urqlClient()}>
        <React.StrictMode>
          <BrowserRouter>
            <modules.Header />
            <modules.sideMenu.SideMenu />
            <AppRoute />
            <modules.Footer />
          </BrowserRouter>
        </React.StrictMode>
      </Urql.Provider>
    </QueryClientProvider>
  )
}

export default App
