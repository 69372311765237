import { customAxios } from 'src/services/api'
import { TUNA_CLASSIFY } from 'src/services/api/tuna-model/url'
import { ITunaAssetment } from 'src/interface/services/api/tuna'

// Get image url
const tunaModelAssetment = (data: ITunaAssetment) => {
  return customAxios.post(TUNA_CLASSIFY, {
    image_base64: data.image_base64,
    model_type: data.model_type,
  })
}

export { tunaModelAssetment }
