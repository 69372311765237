import { FC } from 'react'
import { Outlet } from 'react-router-dom'

//* Components
import { layouts } from 'src/components'

interface IPublicRouteProps {}

/* A function component that returns a function component. */
const PublicRoute: FC<IPublicRouteProps> = () => {
  return (
    <layouts.MainContentsLayout>
      <Outlet />
    </layouts.MainContentsLayout>
  )
}

export default PublicRoute
