import { createClient, dedupExchange, fetchExchange } from 'urql'

import { api } from 'src/constants'

const urqlClient = () =>
  createClient({
    url: api.API_DATA_URL,
    requestPolicy: 'cache-and-network',
    exchanges: [dedupExchange, fetchExchange],
  })

export default urqlClient
