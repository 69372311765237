import { selector } from 'recoil'
import {
  loadingAtom,
  sideMenuPosistionAtom,
} from 'src/store/recoil/application/atom'

const loadingSelector = selector({
  key: 'loadingSelector',
  get: ({ get }) => get(loadingAtom).loading,
  set: ({ set }, newValue) => {
    set(loadingAtom, { loading: newValue })
  },
})

const sideMenuPosistionSelector = selector({
  key: 'sideMenuPosistionSelector',
  get: ({ get }): string => get(sideMenuPosistionAtom).sideMenuPosistion,
  set: ({ set }, newValue: string) => {
    set(sideMenuPosistionAtom, { sideMenuPosistion: newValue })
  },
})

export { loadingSelector, sideMenuPosistionSelector }
