import { FC, ReactNode } from 'react'

import { ButtonLoading } from 'src/components/loading'

export interface ButtonPropsInterface {
  rounded?: string
  bgcolor?: string
  width?: string
  height?: string
  children?: ReactNode
  disabled?: boolean
  loading?: boolean
  formId?: string
  wrapClass?: string
  img?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: any
}
export interface TooltipPropsInterface {
  tooltipTarget?: string
  tooltipPlacement?: string
  role?: string
}

export interface ButtonWithTooltipPropsInterface
  extends ButtonPropsInterface,
    TooltipPropsInterface {
  WrapClassName?: string
  icon?: JSX.Element
  onlyIcon?: boolean
}

/**
 * It takes in a bunch of props and returns a button with those props
 * @param props - Props
 */
const Button: FC<ButtonPropsInterface> = (props) => {
  const {
    bgcolor,
    children,
    width,
    height,
    rounded,
    wrapClass,
    formId,
    loading,
    disabled,
    type,
    onClick,
  } = props

  return (
    <>
      <div className="flex justify-center">
        {!loading ? (
          <button
            form={formId}
            disabled={disabled}
            type={type}
            onClick={onClick}
            className={`${width} ${height} ${rounded} ${bgcolor} ${
              !disabled ? '' : 'opacity-25 cursor-not-allowed'
            }
            ${
              !disabled ? 'hover:opacity-90' : ''
            } flex justify-center items-center rounded-md ${wrapClass}`}
          >
            {children}
          </button>
        ) : (
          <ButtonLoading.ButtonWithSpinLoading
            type="submit"
            bgcolor={bgcolor}
            rounded={rounded}
            width={width}
            height={height}
            wrapClass={`${wrapClass}text-white font-medium shadow-md`}
            children={children}
          />
        )}
      </div>
    </>
  )
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  type: 'button',
  bgcolor:
    'bg-gradient-to-br from-primary via-colorGradientMiddle to-colorGradientEnd ',
}

export { Button }
