import {
  ITunaQualityCheckResult,
  IRecordDelte,
} from 'src/interface/pages/qualityCheck'
import { map } from 'src/constants'

const handleCreateRecord = async (
  values: ITunaQualityCheckResult,
  createRecord: any,
) => {
  const valiables = {
    data: {
      imagePathOrigin: values.image_origin,
      imagePathResult: values.image_result,
      cleanLoinSev: 0,
      orageMeatSev: 0,
      pastySev: 0,
      bruiseSev: 0,
      metalStainSev: 0,
      curdServ: 0,
      modelType: values.model_type,
    },
  }

  values.severities.forEach((severity) => {
    valiables.data[map.tunaModelMap.get(severity.name).db_name] = severity.value
  })

  await createRecord(valiables)
}

const handleDeleteRecord = async (values: IRecordDelte, deleteRecord: any) => {
  const variable = {
    where: values,
  }

  await deleteRecord(variable)
}
export { handleCreateRecord, handleDeleteRecord }
