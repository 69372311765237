import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'

interface ISideMenuLinkProps {
  title: string
  to: string
  icon: any
}

const SideMenuLink: FC<ISideMenuLinkProps> = (props) => {
  return (
    <>
      <li className="h-10">
        <NavLink
          to={props.to}
          className={(navData) =>
            navData.isActive
              ? `flex items-center gap-2 text-base bg-sideBarSelect shadow-2xl
             text-textPrimary font-semibold px-2 py-3 rounded-r-xl hover:bg-sideBarSelect 
              hover:bg-opacity-80 hover:text-secondary active:text-white`
              : `flex items-center gap-2 text-base text-black 
              font-semibold px-2 py-3 rounded-r-xl hover:bg-sideBarSelect hover:bg-opacity-80 
              hover:text-secondary active:text-white `
          }
        >
          <div className="ml-2">{props.icon}</div>
          <div className="ml-2">{props.title}</div>
        </NavLink>
      </li>
    </>
  )
}

export default SideMenuLink
