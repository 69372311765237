import { api } from 'src/constants'

/**
 * It takes an image, converts it to base64, and returns a promise that resolves to the base64 string
 * @param {any} img - any - this is the image that you want to convert to base64
 * @returns A promise that resolves to a string
 */
const convertBase64 = (img: File) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.readAsDataURL(img)

    fileReader.onload = () => {
      resolve(fileReader.result.toString().split(',')[1])
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

const convertPath2URL = (image_path: string) => {
  return `${api.STORAGE_URL}/${image_path}`
}

export { convertBase64, convertPath2URL }
