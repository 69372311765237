import React from 'react'
import { useRecoilState } from 'recoil'

import { recoil } from 'src/store'
import { useScreen } from 'src/hooks'

import { screens } from 'src/constants'
import buttonSideBar from 'src/assets/button-side-bar.svg'
import xButtonSideBar from 'src/assets/x-button-side-bar.svg'

// * Services
const Header = () => {
  const [sidemenuPos, setSidemenuPos] = useRecoilState(
    recoil.applicationStore.selector.sideMenuPosistionSelector,
  )

  const isScreen = useScreen()

  const onClickButtonSidebar = () => {
    sidemenuPos === '-left-64'
      ? setSidemenuPos('-left-0')
      : setSidemenuPos('-left-64')
  }

  return (
    <>
      <div
        className="sticky flex justify-between items-center h-header 
                    bg-gradient-to-b from-primary via-colorGradientMiddle to-colorGradientEnd 
                    shadow-md px-3 top-0 left-0 w-full z-10"
      >
        <div className="w-11/12 flex flex-row justify-between">
          <button
            className={`${
              isScreen === screens.screen.MOBILE ||
              isScreen === screens.screen.TABLET
                ? ''
                : 'hidden'
            }`}
            onClick={onClickButtonSidebar}
          >
            {sidemenuPos === '-left-64' ? (
              <img src={buttonSideBar} alt="button side bar" />
            ) : (
              <img src={xButtonSideBar} alt="x button side bar" />
            )}
          </button>
          <h6 className="text-xl text-textPrimary font-semibold">
            Tuna Quality Assessment
          </h6>
        </div>
      </div>
    </>
  )
}

export default Header
