import React, { useState, useEffect } from 'react'
import { useMutation } from 'urql'
import * as reactQuery from 'react-query'

import { snackBar } from 'src/components'
import { helpers } from 'src/utils'
import { apiService, graphqlService } from 'src/services'

import { api } from 'src/constants'
import QualityCheckResultPage from 'src/pages/qualityCheck/QualityCheckResultPage'
import QualityCheckSelectPage from 'src/pages/qualityCheck/QualityCheckSelectPage'
import QualityCheckModalPage from 'src/pages/qualityCheck/QualityCheckModalPage'
import { ITunaQualityCheckResult } from 'src/interface/pages/qualityCheck'
import { IModalData } from 'src/interface/components/modal'

const QualityCheckPage: React.FC = () => {
  const [classType, setClassType] = useState(api.MODEL_TYPE_CLASS4)
  const [tuanResult, setTunaResult] = useState<ITunaQualityCheckResult>()
  const [modalData, setModalData] = useState<IModalData>({
    title: '',
    isShowing: false,
  })
  const [openSnackBarState, setOpenSnackBarState] = useState(false)

  const [createRecordResult, createRecord] = useMutation(
    graphqlService.record.mutation.createRecord,
  )

  const mutationTunaAssetment = reactQuery.useMutation(
    apiService.tunaModel.mutation.tunaModelAssetment,
  )

  const inputRef = React.useRef(null)

  const webcamRef = React.useRef(null)

  const onSelectDropDown = (data) => {
    helpers.generals.localStore.setClasses(data)
    setClassType(data)
  }

  const handdleOnClickCamera = () => {
    setModalData({ title: 'Capture image', isShowing: true })
  }

  const handdleOnClickFolder = () => {
    inputRef.current.click()
  }

  const handdleRequest = async (file_base64, class_type) => {
    const result = await apiService.tunaModel.handler.tunaModelAssetmentHandler(
      mutationTunaAssetment,
      {
        image_base64: file_base64,
        model_type: class_type,
      },
    )

    setTunaResult({
      ...result,
      image_origin: file_base64,
      model_type: classType,
    })
  }

  const handdleOnClickCapture = async (data) => {
    const imageSrc = data
    const file_base64 = imageSrc.split(',')[1]

    setModalData({ title: 'Camera', isShowing: false })
    await handdleRequest(file_base64, classType)
  }

  const handleSelectFile = async (event) => {
    const file = event.target.files as FileList
    if (file.length < 1) return

    const file_base64 = await helpers.generals.file.convertBase64(file[0])
    await handdleRequest(file_base64, classType)
  }

  const handdleOnSave = () => {
    graphqlService.record.handler.handleCreateRecord(tuanResult, createRecord)

    setTunaResult(undefined)
    // Save record here
    setOpenSnackBarState(true)
  }

  const handdleOnCancel = () => {
    setTunaResult(undefined)
  }

  useEffect(() => {
    const classStore = helpers.generals.localStore.getClasses()
    if (classStore !== '') setClassType(classStore)
  }, [])

  const RenderCard = () => {
    if (tuanResult)
      return (
        <QualityCheckResultPage
          tuanResult={tuanResult}
          handdleOnCancel={handdleOnCancel}
          handdleOnSave={handdleOnSave}
          isLoading={createRecordResult.fetching}
        />
      )

    return (
      <QualityCheckSelectPage
        inputRef={inputRef}
        classType={classType}
        handdleOnClickCamera={handdleOnClickCamera}
        handdleOnClickFolder={handdleOnClickFolder}
        handleSelectFile={handleSelectFile}
        onSelectDropDown={onSelectDropDown}
        isLoading={mutationTunaAssetment.isLoading}
      />
    )
  }

  return (
    <>
      <div className="flex justify-center my-5">{RenderCard()}</div>
      <snackBar.SnackBarCom
        message="Success"
        open={openSnackBarState}
        setOpen={setOpenSnackBarState}
      />
      <QualityCheckModalPage
        modalData={modalData}
        webcamRef={webcamRef}
        setModalData={setModalData}
        handdleOnClickCapture={handdleOnClickCapture}
      />
    </>
  )
}

export default QualityCheckPage
