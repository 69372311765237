import React, { Dispatch, SetStateAction } from 'react'
import Dialog from '@mui/material/Dialog'

import { button } from 'src/components'
import questionMark from 'src/assets/question-mark.svg'

interface IDialogComProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClickConfirm: (e?: any) => void
}

const DialogCom: React.FC<IDialogComProps> = (props) => {
  const { open, setOpen, onClickConfirm } = props

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="round-lg"
      >
        <div className="flex flex-col gap-3 px-5 py-3">
          <div className="flex justify-center">
            <img src={questionMark} alt="fodler button" />
          </div>
          <div className="flex justify-center">
            <h1 className="text-2xl">Are you sure ?</h1>
          </div>
          <div className="flex flex-row justify-between px-3 py-5 gap-4">
            <button.Button
              width="w-32"
              height="h-10"
              bgcolor="bg-disabled"
              onClick={handleClose}
            >
              <h1 className="text-black">Disagree</h1>
            </button.Button>
            <button.Button
              width="w-32"
              height="h-10"
              bgcolor="bg-bgConfirm"
              onClick={onClickConfirm}
            >
              <h1 className="text-black">Agree</h1>
            </button.Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default DialogCom
