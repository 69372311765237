import { FC, ReactElement } from 'react'

type IPreviewImageProps = {
  image_base64: string
}

const PreviewImage: FC<IPreviewImageProps> = (props): ReactElement => {
  const { image_base64 } = props
  return (
    <div className="w-56 h-64">
      <img
        src={`data:image/jpeg;base64,${image_base64}`}
        onError={(e: any) => {
          ;(e.target as HTMLElement).onerror = null
        }}
        alt="Result image preview"
        className="object-contain w-56 h-64 border-2 bg-bgSecondary border-black"
      />
    </div>
  )
}

export default PreviewImage
